import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'

export function AsideMenuMain() {
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Builder Layout'
        fontIcon='bi-layers'
      /> */}

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Menu</span>
        </div>
      </div> */}

      {/* ********************************************************************************************************** */}

      {/* //============== Organization ================================= */}
      <AsideMenuItemWithSub
        to='/organization'
        title='Organization'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen025.svg'
        // dislpay={user.roleID === 4 ? 'd-none' : ''}
      >
        <AsideMenuItem to='/organization/employee' title='Employee' hasBullet={true} />
        <AsideMenuItem to='/organization/user' title='User' hasBullet={true} />
        <AsideMenuItem to='/organization/customer' title='Customer' hasBullet={true} />
        <AsideMenuItem
          to='/organization/field-executive'
          title='Field Executive'
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      {/* //==================== Product Manage ===================== */}
      <AsideMenuItemWithSub
        to='/product-manage'
        title='Product Manage'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen019.svg'
      >
        {/* <AsideMenuItem to='/product-manage/service-category' title='Service' hasBullet={true} /> */}
        <AsideMenuItem
          to='/product-manage/item-category'
          title='Product Category'
          hasBullet={true}
        />
        <AsideMenuItem to='/product-manage/items' title='Product' hasBullet={true} />
        {/* <AsideMenuItem to='/product-manage/driver-tip' title='Driver Tip' hasBullet={true} /> */}
      </AsideMenuItemWithSub>

      {/* //==================== Location ===================== */}
      <AsideMenuItemWithSub
        to='/locations'
        title='Locations'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen025.svg'
      >
        <AsideMenuItem to='/locations/state' title='State' hasBullet={true} />
        <AsideMenuItem to='/locations/city' title='City' hasBullet={true} />
        <AsideMenuItem to='/locations/areapincode' title='Area Pincode' hasBullet={true} />
        <AsideMenuItem to='/locations/society' title='Society' hasBullet={true} />
        {/* <AsideMenuItem to='/locations/pickup-drop-point' title='Kiosk Point' hasBullet={true} /> */}
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/master'
        title='Master'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
        // dislpay={user.roleID === 4 ? 'd-none' : ''}
      >
        <AsideMenuItem to='/master/designation' title='Designation' hasBullet={true} />
        <AsideMenuItem to='/master/documenttype' title='Document Type' hasBullet={true} />
        <AsideMenuItem to='/master/eduCategory' title='Education Category' hasBullet={true} />
        <AsideMenuItem to='/master/eduDepartment' title='Education Department' hasBullet={true} />
        <AsideMenuItem to='/master/nationality' title='Nationality' hasBullet={true} />

        <AsideMenuItem to='/master/branch' title='Branch' hasBullet={true} />
        {/* <AsideMenuItem to='/master/offer' title='Offer' hasBullet={true} /> */}
        <AsideMenuItem to='/master/bannerimage' title='Bennner Image' hasBullet={true} />
        {/* <AsideMenuItem to='/master/expense-type' title='Expense Type' hasBullet={true} /> */}
        {/* <AsideMenuItem to='/master/homevideo' title='Home Video' hasBullet={true} /> */}
      </AsideMenuItemWithSub>

      {/* ======================================Offer Master=============================== */}
      <AsideMenuItem
        to='/offer'
        title='Offer'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen024.svg'
      />

      {/* //============== Orders ================================= */}
      {/* <AsideMenuItemWithSub
        to='/orders'
        title='Orders'
        fontIcon='bi-layers'
        icon='/media/icons/duotune/general/gen017.svg'
        // dislpay={user.roleID === 4 ? 'd-none' : ''}
      >
        <AsideMenuItem to='/orders/open-order' title='Open Order' hasBullet={true} />
        <AsideMenuItem to='/orders/close-order' title='Close Order' hasBullet={true} />
        <AsideMenuItem to='/orders/mismatch-order' title='Mismatch Order' hasBullet={true} />
        <AsideMenuItem to='/orders/tip-manage' title='Tip Manage' hasBullet={true} />
        <AsideMenuItem to='/orders/feedback' title='Feedback' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* ======================================Reports Master=============================== */}
      
      {/* <AsideMenuItem
        to='/reports/list'
        title='Reports'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen024.svg'
      /> */}

      {/* ************************************************ fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'********************************************************** */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>

      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
