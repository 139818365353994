import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_Laundry/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import FieldExecutiveMasterPage from '../modules/organization-page/field-executive-master-page/FieldExecutiveMasterPage'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // ============================ Master ========================
  const CountryMasterPage = lazy(
    () => import('../modules/master-page/country-master-page/CountryMasterPage')
  )
  // ================================================================
  const StateMasterPage = lazy(
    () => import('../modules/master-page/state-master-page/StateMasterPage')
  )
  // ================================================================
  const CityMasterPageMasterPage = lazy(
    () => import('../modules/master-page/district-master-page/CityMasterPageMasterPage')
  )
  // ================================================================
  const TalukaMasterPage = lazy(
    () => import('../modules/master-page/taluka-master-page/TalukaMasterPage')
  )
  // ================================================================
  const NationalityMasterPage = lazy(
    () => import('../modules/master-page/nationality-master-page/NationalityMasterPage')
  )
  // ================================================================
  const EducationCategoryPage = lazy(
    () => import('../modules/master-page/education-category-master-page/EducationCategoryPage')
  )
  // ================================================================
  const EducationDepartmentPage = lazy(
    () => import('../modules/master-page/education-department-master-page/EducationDepartmentPage')
  )
  // ================================================================
  const DocumentTypePage = lazy(
    () => import('../modules/master-page/document-type-page/DocumentTypePage')
  )
  // ================================================================
  const KycDocumentMasterPage = lazy(
    () => import('../modules/master-page/kyc-document-page/KycDocumentMasterPage')
  )
  // ================================= Organization ==========================
  const EmployeeMasterPage = lazy(
    () => import('../modules/organization-page/employee-master-page/EmployeeMasterPage')
  )
  // ================================================================
  const UserMasterPage = lazy(
    () => import('../modules/organization-page/user-master-page/UserMasterPage')
  )
  // ================================================================
  const CustomerMasterPage = lazy(
    () => import('../modules/organization-page/customer-master-page/CustomerMasterPage')
  )
  // ==================================================================
  const FieldExecutiveMasterPage = lazy(
    () =>
      import('../modules/organization-page/field-executive-master-page/FieldExecutiveMasterPage')
  )

  // ================================================================
  const DepartmentMasterPage = lazy(
    () => import('../modules/master-page/department-master-page/DepartmentMasterPage')
  )
  // ================================================================
  const DesignationMasterPage = lazy(
    () => import('../modules/master-page/designation-master-page/DesignationMasterPage')
  )
  // ================================================================
  const BranchMasterPage = lazy(
    () => import('../modules/master-page/branch-master-page/BranchMasterPage')
  )
  // ================================================================
  const OrganizationBankMasterPage = lazy(
    () => import('../modules/master-page/organization-bank-master-page/OrganizationBankMasterPage')
  )
  // ================================================================
  const ChangePasswordPage = lazy(() => import('../modules/change-password/ChangePasswordPage'))

  // ================================================================
  const AreaPinCodeMasterPage = lazy(
    () => import('../modules/master-page/area-pincode-master-page/AreaPinCodeMasterPage')
  )

  // ================================================================
  const SocietyMasterPage = lazy(
    () => import('../modules/master-page/society-master-page/SocietyMasterPage')
  )
  // ========================================================================================
  const ServiceCategoryMasterPage = lazy(
    () => import('../modules/master-page/service-category-master-page/ServiceCategoryMasterPage')
  )
  // ========================================================================================
  const ServiceSubCategoryMasterPage = lazy(
    () =>
      import('../modules/master-page/service-subcategory-master-page/ServiceSubCategoryMasterPage')
  )

  // ========================================================================================
  const ItemCategoryMaterPage = lazy(
    () => import('../modules/master-page/item-category-master-page/ItemCategoryMaterPage')
  )

  // ========================================================================================
  const ItemMaterPage = lazy(() => import('../modules/master-page/item-master-page/ItemMasterPage'))

  // ====================================driver tip====================================================
  const DriverTipMaterPage = lazy(
    () => import('../modules/master-page/driver-tip-master-page/DriverTipMasterPage')
  )
  // ========================================================================================
  const PickupDropPointMasterPage = lazy(
    () => import('../modules/master-page/pickup-drop-point-master-page/PickupDropPointMasterPage')
  )
  // ============================================================================
  const PickupScheduleMasterPage = lazy(
    () => import('../modules/schedules-mst-pages/PickupScheduleMasterPage')
  )
  // ============================================================================
  const DeliveryScheduleMasterPage = lazy(
    () => import('../modules/schedules-mst-pages/DeliveryScheduleMasterPage')
  )
  // ============================================================================
  const BannerImageMasterPage = lazy(
    () => import('../modules/master-page/banner-image-master-page/BannerImageMasterPage')
  )
  // ============================================================================
  const HomeVideoMasterPage = lazy(
    () => import('../modules/master-page/home-video-master-page/HomeVideoMasterPage')
  )
  // ============================================================================
  const ConfigurationMasterPage = lazy(
    () => import('../modules/configuration-master-page/ConfigurationMasterPage')
  )
  // ============================================================================
  const OfferMasterPage = lazy(
    () => import('../modules/master-page/offer-master-page/OfferMasterPage')
  )
  const ExpenseTypeMasterPage = lazy(
    () => import('../modules/master-page/expense-type-master-page/ExpenseTypeMasterPage')
  )
  // ===================================orders=========================================
  const OpenOrderMasterPage = lazy(() => import('../modules/orders/open-order/OpenOrderMasterPage'))
  const CloseOrderMasterPage = lazy(
    () => import('../modules/orders/close-order/CloseOrderMasterPage')
  )
  const MismatchOrderMasterPage = lazy(
    () => import('../modules/orders/mismatch-order/MismatchOrderMasterPage')
  )
  const TipManageMasterPage = lazy(() => import('../modules/orders/tip-manage/TipManageMasterPage'))
  const ReportsMasterPage = lazy(() => import('../modules/reports-master-page/ReportsMasterPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Route path='/master/country' component={CountryMasterPage} />
        <Route path='/master/taluka' component={TalukaMasterPage} />
        <Route path='/master/nationality' component={NationalityMasterPage} />
        <Route path='/master/eduDepartment' component={EducationDepartmentPage} />
        <Route path='/master/eduCategory' component={EducationCategoryPage} />
        <Route path='/master/documenttype' component={DocumentTypePage} />
        <Route path='/master/kycdocument' component={KycDocumentMasterPage} />
        <Route path='/master/bannerimage' component={BannerImageMasterPage} />
        <Route path='/master/homevideo' component={HomeVideoMasterPage} />
        <Route path='/master/expense-type' component={ExpenseTypeMasterPage} />

        <Route path='/organization/employee' component={EmployeeMasterPage} />
        <Route path='/organization/user' component={UserMasterPage} />
        <Route path='/organization/customer' component={CustomerMasterPage} />
        <Route path='/organization/field-executive' component={FieldExecutiveMasterPage} />
        <Route path='/master/department' component={DepartmentMasterPage} />
        <Route path='/master/designation' component={DesignationMasterPage} />
        <Route path='/master/branch' component={BranchMasterPage} />
        <Route path='/master/bank' component={OrganizationBankMasterPage} />
        <Route path='/master/service-subcategory' component={ServiceSubCategoryMasterPage} />

        {/* ==================== Product Manage ============== */}
        <Route path='/product-manage/service-category' component={ServiceCategoryMasterPage} />
        <Route path='/product-manage/item-category' component={ItemCategoryMaterPage} />
        <Route path='/product-manage/items' component={ItemMaterPage} />
        <Route path='/product-manage/driver-tip' component={DriverTipMaterPage} />

        {/* ==================== Locations ============== */}
        <Route path='/locations/state' component={StateMasterPage} />
        <Route path='/locations/city' component={CityMasterPageMasterPage} />
        <Route path='/locations/areapincode' component={AreaPinCodeMasterPage} />
        <Route path='/locations/society' component={SocietyMasterPage} />
        <Route path='/locations/pickup-drop-point' component={PickupDropPointMasterPage} />

        {/* //=============== Schedules ============= */}
        <Route path='/schedules/pickup-schedule' component={PickupScheduleMasterPage} />
        <Route path='/schedules/delivery-schedule' component={DeliveryScheduleMasterPage} />
        {/* //=============== Configuration ============= */}
        <Route path='/configuration' component={ConfigurationMasterPage} />

        {/* //=============== Configuration ============= */}
        <Route path='/offer' component={OfferMasterPage} />
        {/* //=============== orders ============= */}
        <Route path='/orders/open-order' component={OpenOrderMasterPage} />
        <Route path='/orders/close-order' component={CloseOrderMasterPage} />
        <Route path='/orders/mismatch-order' component={MismatchOrderMasterPage} />
        <Route path='/orders/tip-manage' component={TipManageMasterPage} />
        {/* //=============== Reports ============= */}
        <Route path='/reports/list' component={ReportsMasterPage} />

        <Redirect from='/' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
