/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_Laundry/helpers'

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <>
      {/* <AuthSidePage /> */}
      {/* <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={
          {
            // backgroundImage: `url(${toAbsoluteUrl('/media/logos/share_market2.jpg')})`,
          }
        }
      > */}
      {/* begin::Content  */}
      {/* <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'> */}
      {/* begin::Logo  */}
      {/* <span className='mb-12'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/Logo01.png')} className='h-60px' />
          </span> */}
      {/* end::Logo  */}

      {/* <div className='container'> */}
      {/* <div className='row m-0 p-0 no-gutters shadow-lg'>
        <div className='col-md-6 d-none d-md-block'>
          <img
            src={`${toAbsoluteUrl('/media/illustrations/dozzy-1/15.png')}`}
            className='img-fluid'
            style={{minHeight: '90%'}}
          />
        </div>
        {/* begin::Wrapper
        <div className='col-md-6 bg-white'>
          <Switch>
            <Route path='/auth/login' component={Login} />
            <Route path='/auth/registration' component={Registration} />
            <Route path='/auth/forgot-password' component={ForgotPassword} />
            <Redirect from='/auth' exact={true} to='/auth/login' />
            <Redirect to='/auth/login' />
          </Switch>
        </div> */}
      <div className='row align-items-center g-0'>
        <div className='col-md-6 text-center'>
          <img
            src={`${toAbsoluteUrl('/media/illustrations/dozzy-1/shakwala.jpg')}`}
            className='img-fluid mt-8'
            width={'90%'}
          />
        </div>
        {/* begin::Wrapper  */}
        <div className='col-md-6'>
          <Switch>
            <Route path='/auth/login' component={Login} />
            <Route path='/auth/registration' component={Registration} />
            <Route path='/auth/forgot-password' component={ForgotPassword} />
            <Redirect from='/auth' exact={true} to='/auth/login' />
            <Redirect to='/auth/login' />
          </Switch>
        </div>
        {/* end::Wrapper  */}
      </div>
      {/* </div> */}
      {/* </div> */}
      {/* end::Content  */}
      {/* begin::Footer  */}
      {/* <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='#' className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a>
        </div>
      </div>  */}
      {/* end::Footer  */}
      {/* </div> */}
    </>
  )
}
